import React from 'react';

import './style.css';

const AppliConso = () => {
    return (
        <div>
            <h2>Hello, Appli conso</h2>
        </div>
    );
};

export default AppliConso;