import React from 'react';

const Error = () => {
    return (
        <div>
            <h2><br />404 <br /><br />-<br /><br /> Page non trouvée.</h2>
        </div>
    );
};

export default Error;